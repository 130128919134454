// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import { createRoot } from "react-dom/client"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container)
    root.render(element)
  }
}
